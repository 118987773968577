import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles from "./Warehouse.module.css"

import ReactAutocomplete from "react-google-autocomplete";

const PopUpInventory = ({ param, popUpPage, popUpControls, newInventoryData, validationError, allInventory, handleNewInventoryData, subCatOptions, formatOptions, allSuppliers, indPopUpControls, itemExisting, indLoading }) => {
	return (
		<div className={styles.popup_container}>
			{indLoading
				?
					<div className={styles.loadpage_container_white}>
						<div className={styles.load}>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
				:
					<>
						<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

						{popUpPage == 1 &&
							<>
								<h1 className={styles.medium_font}>Product details</h1>
								<p className={styles.smaller_font}>Please enter the specific product details to create.</p>

								<div className={styles.popup_form}>
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Product name</label>
										<input type="text" value={newInventoryData.item_name} placeholder="Mango" onChange={(input) => handleNewInventoryData("item_name", input)}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Supplier</label>
										<Select
											closeMenuOnSelect={true}
											options={formatOptions(newInventoryData.supplier_names.map(sup => sup.supplier_name))}
											styles={{
												control: (baseStyles, state) => ({
													...baseStyles,
													boxShadow: 'none !important',
												   '&:hover': {
												       borderColor: '#fce7eb !important'
												    },
													borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
												}),
											}}
											onChange={(selected) => handleNewInventoryData("supplier", selected)}
											value={{label: newInventoryData["supplier"], value: newInventoryData["supplier"]}}
										/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Category</label>
										<Select
											closeMenuOnSelect={true}
											options={[{label: 'Fruit', value: 'Fruit'}, {label: 'Milk', value: 'Milk'}, {label: 'Bread', value: 'Bread'}, {label: 'Coffee', value: 'Coffee'}, {label: 'Cakes', value: 'Cakes'}, {label: 'Catering', value: 'Catering'}]}
											styles={{
												control: (baseStyles, state) => ({
													...baseStyles,
													boxShadow: 'none !important',
												   '&:hover': {
												       borderColor: '#fce7eb !important'
												    },
													borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
												}),
											}}
											onChange={(selected) => handleNewInventoryData("category", selected)}
											value={{label: newInventoryData["category"], value: newInventoryData["category"]}}
										/>
									</div>

									{(subCatOptions && subCatOptions.length > 0) &&
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Sub-category</label>
											<CreatableSelect
												closeMenuOnSelect={true}
												options={formatOptions(subCatOptions)}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewInventoryData("subcategory", selected)}
												value={{label: newInventoryData["subcategory"], value: newInventoryData["subcategory"]}}
											/>
										</div>
									}

									{newInventoryData['category'] == "Fruit" &&
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Seasonal product?</label>
											<div className={styles.radio_container}>
												<label className={styles.radio_button}>Yes
											  		<input type="radio" name="radio" checked={newInventoryData.is_seasonal === true && "checked"} onChange={(selected) => handleNewInventoryData("is_seasonal", {value: true})}/>
											    	<span className={styles.checkmark}></span>
											  	</label>

											  	<label className={styles.radio_button}>No
											    	<input type="radio" name="radio" checked={newInventoryData.is_seasonal === false && "checked"} onChange={(selected) => handleNewInventoryData("is_seasonal", {value: false})}/>
											    	<span className={styles.checkmark}></span>
											  	</label>
											</div>
										</div>
									}

									{newInventoryData['category'] == "Milk" &&
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Crate quantity?</label>
											<input type="text" value={newInventoryData.crate_qty} placeholder="9" onChange={(input) => handleNewInventoryData("crate_qty", input)}/>
										</div>
									}

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>State available</label>
										<Select
											closeMenuOnSelect={true}
											isMulti={true}
											options={[{label: 'VIC', value: 'VIC'}, {label: 'NSW', value: 'NSW'}]}
											styles={{
												control: (baseStyles, state) => ({
													...baseStyles,
													boxShadow: 'none !important',
												   '&:hover': {
												       borderColor: '#fce7eb !important'
												    },
													borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
												}),
											}}
											onChange={(selected) => handleNewInventoryData("states_available", selected)}
											value={newInventoryData['states_available'] && formatOptions(newInventoryData['states_available'])}
										/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Custom Cut-Off</label>
										<input type="text" value={newInventoryData.custom_cutoff} placeholder="1" onChange={(input) => handleNewInventoryData("custom_cutoff", input)}/>
									</div>
								</div>
							</>
						}

						{popUpPage == 2 &&
							<>
								<h1 className={styles.medium_font}>Product details</h1>
								<p className={styles.smaller_font}>Please enter the specific product details to create.</p>

								<div className={styles.popup_form}>
									<div className={styles.popup_form_flex}>
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Purchase price</label>
											<input type="text" value={newInventoryData.purchase_price} placeholder="$2.15" onChange={(input) => handleNewInventoryData("purchase_price", input)}/>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Sale price</label>
											<input type="text" value={newInventoryData.sale_price} placeholder="$3.75" onChange={(input) => handleNewInventoryData("sale_price", input)}/>
										</div>
									</div>

									<div className={styles.popup_form_flex}>
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>GST</label>
											<Select
												closeMenuOnSelect={true}
												options={[{label: 'Yes', value: 'Yes'}, {label: 'No', value: 'No'}]}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewInventoryData("gst", selected)}
												value={{label: newInventoryData["gst"], value: newInventoryData["gst"]}}
											/>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Stock</label>
											<input type="text" value={newInventoryData.stock} placeholder="322 bottles" onChange={(input) => handleNewInventoryData("stock", input)}/>
										</div>
									</div>

									{newInventoryData['category'] == "Milk" &&
										<div className={styles.popup_form_flex}>
											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Width (cm)</label>
												<input type="text" value={newInventoryData.width} placeholder="7.5" onChange={(input) => handleNewInventoryData("width", input)}/>
											</div>

											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Length (cm)</label>
												<input type="text" value={newInventoryData.length} placeholder="11" onChange={(input) => handleNewInventoryData("length", input)}/>
											</div>
										</div>
									}	
									
								</div>
							</>
						}

						{popUpPage == 3 &&
							<>
								<h1 className={styles.medium_font}>Confirm details</h1>
								<p className={styles.smaller_font}>Please confirm all the details you have entered.</p>


								<div className={styles.popup_form}>
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Product name</label>
										<input type="text" value={newInventoryData.item_name} disabled={true}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Supplier</label>
										<input type="text" value={newInventoryData.supplier} disabled={true}/>
									</div>

									{newInventoryData.is_seasonal &&
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Supplier</label>
											<input type="text" value={newInventoryData.is_seasonal ? "Yes" : "No"} disabled={true}/>
										</div>
									}

									<div className={styles.popup_form_flex}>
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Category</label>
											<input type="text" value={newInventoryData.category} disabled={true}/>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Sub-category</label>
											<input type="text" value={newInventoryData.subcategory} disabled={true}/>
										</div>
									</div>

									<div className={styles.popup_form_flex}>
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Purchase price</label>
											<input type="text" value={newInventoryData.purchase_price} disabled={true}/>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Sale price</label>
											<input type="text" value={newInventoryData.sale_price} disabled={true}/>
										</div>
									</div>

									<div className={styles.popup_form_flex}>
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>GST</label>
											<input type="text" value={newInventoryData.gst || '-'} disabled={true}/>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Stock</label>
											<input type="text" value={newInventoryData.stock || '-'} disabled={true}/>
										</div>
									</div>

									<div className={styles.popup_form_flex}>
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>States available</label>
											<input type="text" value={newInventoryData.states_available && newInventoryData.states_available.join(', ')} disabled={true}/>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Crate quantity</label>
											<input type="text" value={newInventoryData.crate_qty || '-'} disabled={true}/>
										</div>
									</div>

									{newInventoryData['category'] == "Milk" &&
										<div className={styles.popup_form_flex}>
											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Width</label>
												<input type="text" value={newInventoryData.width || '0'} disabled={true}/>
											</div>

											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Length</label>
												<input type="text" value={newInventoryData.length || '0'} disabled={true}/>
											</div>
										</div>
									}
								</div>

							</>
						}

						{validationError &&
							<>
							{validationError.includes('Button:')
								?
									<button className={`${styles.notification} ${styles.validationError}`} onClick={() => indPopUpControls('nav_item', itemExisting)}>
										<p className={styles.smaller_font}>{validationError.split('Button:')[1]}</p>
									</button>
								:
									<div className={`${styles.notification} ${styles.validationError}`}>
										<p className={styles.smaller_font}>{validationError}</p>
									</div>
							}
							</>
						}

						<div className={styles.popUpControls}>
							<div className={styles.popUpControlsContainer}>
					      		{popUpPage != 1 &&
					      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
					      		}

					      		{popUpPage != 3 &&
					      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
					      		}

					      		{popUpPage == 3 &&
					      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
					       		}
				       		</div>
			       		</div>
			       	</>
       		}
		</div>
	)
}

export default PopUpInventory;