import styles from "./Warehouse.module.css"

const PopUpIndInventory = ({ param, indPopUpControls, indInventory, indNav, changeIndNav, allSuppliers, changeStatus, changingStatus, allLogs, formatDate, searchControl, indLoading }) => {
	return (
		<div className={styles.indsite_container}>
			<div className={styles.indsite_popup}>
				{indLoading
					?
						<div className={styles.loadpage_container}>
							<div className={styles.load}>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
							</div>
						</div>
					:

						<div className={styles.indsite_inner}>
							<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
							<button className={styles.editPopup} onClick={() => indPopUpControls('edit')}><i className="fa-solid fa-pen"></i></button>
							<button className={styles.deletePopup} onClick={() => indPopUpControls('delete', indInventory)}><i className="fa-solid fa-trash-can"></i></button>

							<h1 className={styles.medium_font}>Inventory details</h1>
							<p className={styles.smaller_font}>Please find the specific inventory details for this item here.</p>

							<div className={styles.indsite_nav}>
								<div className={styles.sitenav}>
									<button className={`${styles.sitenav_box} ${indNav == 'Details' && styles.sitenav_active}`} onClick={() => changeIndNav('Details')}><p>Details</p></button>
									<button className={`${styles.sitenav_box} ${indNav == 'Logs' && styles.sitenav_active}`} onClick={() => changeIndNav('Logs')}><p>Logs</p></button>
								</div>
							</div>

							{indNav == "Details" &&
								<div className={`${styles.sites_tablecontainer} ${changingStatus && styles.hidden_container}`}>
									<div className={`${styles.indinfo_grid} ${styles.indsiteinfo_gap}`}>
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Item code</label>
											<p>{indInventory.item_code}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Item name</label>
											<p>{indInventory.item_name}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Category</label>
											<p>{indInventory.category}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Subcategory</label>
											<p>{indInventory.subcategory}</p>
										</div>

										{/* FIND BETTER SOLUTION WITHOUT INLINE */}
										<div className={styles.popup_formbox} style={{position: 'relative'}}>
											<div style={{position: 'absolute'}}>
												<div style={{display: 'grid', gridTemplateRows: 'auto', rowGap: '2.5px'}}>
													<label className={styles.smaller_font}>Status</label>
									    			<button onClick={() => changeStatus('toggle')} className={`${styles.site_status} ${(indInventory.active === true) && styles.status_active} ${indInventory.active !== true && styles.status_inactive}`}>
									    				<p className={styles.smaller_font}>{indInventory.active ? "Active" : "Inactive"}</p>
									    			</button>
								    			</div>
							    			</div>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Supplier</label>
											<p className={styles.clickable_nav} onClick={() => indPopUpControls('navigate', { _id: indInventory.supplier })}>{indInventory.supplier_name}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Purchase price</label>
											<p>${indInventory.purchase_price}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Sale price</label>
											<p>${indInventory.sale_price}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Stock</label>
											<p>{indInventory.stock} items</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>GST</label>
											<p>{indInventory.gst ? "10%" : "No"}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>States available</label>
											<p>{indInventory.states_available && indInventory.states_available.join(', ') || '-'}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Seasonal</label>
											<p>{indInventory.is_seasonal ? "Yes" : "No"}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Crate quantity</label>
											<p>{indInventory.crate_qty ? indInventory.crate_qty : "No"}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Width</label>
											<p>{indInventory.width ? `${indInventory.width}cm` : "-"}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Length</label>
											<p>{indInventory.length ? `${indInventory.length}cm` : "-"}</p>
										</div>

										{indInventory.hasOwnProperty('custom_cutoff') &&
											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Custom Cut-Off</label>
												<p>{indInventory.custom_cutoff} days</p>
											</div>
										}
									</div>
								</div>
							}

							{indNav === "Logs" &&
								<div className={styles.sites_tablecontainer}>
									<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
										<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
											<div className={styles.sites_search}>
												<i className="fa-solid fa-magnifying-glass"></i>
												<input
												    type="text"
												    className={styles.smaller_font}
												    placeholder="Search for any of the column headings..."
												    onKeyUp={(event) => searchControl(event, "ind_table")}
												/>
											</div>
										</div>
									</div>

									<div className={styles.table_container}>
										<table className={styles.sites_table} id="ind_table">
										    <thead className={styles.sitesind_thead}>
										      <tr className={styles.table_rowth}>
										      	<th colspan="1">Date</th>
										      	<th colspan="3">Message</th>
										      </tr>
										    </thead>
										    <tbody className={styles.indtbody}>
										    	{indInventory.logs
										    		?
										    		<>
										    			{indInventory.logs.length > 0
										    				? 
										    				<>
											    				{indInventory.logs.reverse().map((ALF) => {
											    					return (
																    	<tr className={styles.sitesind_tr}>
																    		<td colspan="1">
																    			<p>{formatDate(ALF.date_created, false, true).split(' @ ')[0]}</p>
																    			<p>{formatDate(ALF.date_created, false, true).split(' @ ')[1]}</p>
																    		</td>
																    		<td colspan="3"><span>{ALF.log_title}:</span><br/>{ALF.log_message}</td>
																    	</tr>
															    	)
															    })}
														    </>
										    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This item does not have any logs yet!</h1>
										    			}
										    		</>
										    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This item does not have any logs yet!</h1>
										    	}
										    </tbody>
										</table>
									</div>
								</div>
							}

			    			{changingStatus &&
			    				<div className={styles.statusOptions}>
			    					<button className={`${styles.closePopup} ${styles.closeStatusPopup}`} onClick={() => changeStatus('toggle')}><i className="fa-solid fa-x"></i></button>

									<h1 className={styles.medium_font}>Change status</h1>
									<p className={`${styles.smaller_font}`}>Use this module to update the status for {indInventory.item_code}</p>

									<div className={styles.statusOuter}>
										<div className={styles.statusInner}>
							    			{!indInventory.active &&
								    			<button onClick={() => changeStatus('set', 'Inventory', indInventory, true)} className={`${styles.site_status} ${styles.status_active}`}>
								    				<p className={styles.smaller_font}>Active</p>
								    			</button>
								    		}

								    		{indInventory.active &&
								    			<button onClick={() => changeStatus('set', 'Inventory', indInventory, false)} className={`${styles.site_status} ${styles.status_inactive}`}>
								    				<p className={styles.smaller_font}>Inactive</p>
								    			</button>
								    		}
							    		</div>
						    		</div>
			    				</div>
			    			}
						</div>
				}
			</div>
		</div>
	)
}

export default PopUpIndInventory;