import Select from 'react-select';
import ReactAutocomplete from "react-google-autocomplete";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import whole_week from '../../../images/whole_week.jpg'
import ind_date from '../../../images/ind_date.jpg'
import styles from "./Dashboard.module.css"

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const PopUpChristmasClosures = ({ param, popUpControls, validationError, formatDate, christmasClosuresData, handleChristmasClosures }) => {
	return (
		<div className={`${styles.popup_container}`}>
			<button className={styles.closePopup} onClick={() => handleChristmasClosures('close')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Date selection</h1>
			<p className={styles.smaller_font}>Please select the date/date-range you would like to apply.</p>
			
			<div className={styles.popup_formorder}>
				<div className={styles.popup_form_customdate_inv}>
					<div className={`${styles.popup_formbox}`}>
						<div className={styles.label_container}>
							<label className={styles.smaller_font}>From</label>
							<label className={styles.smaller_font}>To</label>
						</div>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateRangePicker
								className={styles.overridebg}
								localeText={{ start: '', end: '' }}
								value={(christmasClosuresData && christmasClosuresData.length > 0) ? christmasClosuresData : [null, null]}
								onChange={(selected) => handleChristmasClosures("set", {value: selected})}
								disablePast
								format="DD/MM/YYYY"
							/>
						</LocalizationProvider>
					</div>
				</div>
			</div>


			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button type="button" className={styles.submitBtn} onClick={() => handleChristmasClosures('submit')}>Submit</button>
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpChristmasClosures;