import styles from "./Dashboard.module.css"

const PopUpIndEnquiry = ({ param, indPopUpControls, indEnquiry, changeIndSiteNav, indSiteNav, formatDate, indLoading }) => {
	return (
		<div className={styles.indsite_container}>
			{indLoading
				?
					<div className={styles.loadpage_container}>
						<div className={styles.load}>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
				:
					<div className={styles.indsite_popup}>
						<div className={styles.indsite_inner}>
							<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
							<button className={styles.editPopup} onClick={() => indPopUpControls('edit')}><i className="fa-solid fa-pen"></i></button>
							<button className={styles.nextPopup} onClick={() => indPopUpControls('delete', indEnquiry)}><i className="fa-solid fa-trash-can"></i></button>

							<h1 className={styles.medium_font}>Enquiry {indSiteNav.toLowerCase()}</h1>
							<p className={styles.smaller_font}>Please find the specific {indSiteNav.toLowerCase()} for your enquiry here.</p>


							<div className={styles.indsite_nav}>
								<div className={styles.sitenav}>
									<button className={`${styles.sitenav_box} ${indSiteNav == 'Details' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Details')}><p>Details</p></button>
								</div>
							</div>

							{indSiteNav == "Details" &&
								<div className={styles.sites_tablecontainer}>
									<div className={`${styles.indinfo_grid} ${styles.indsiteinfo_gap}`}>
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Company</label>
											<p>{indEnquiry.company}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Date created</label>
											<p>{formatDate(indEnquiry.date_created)}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Address</label>
											<a className={styles.clickable_nav} href={`http://maps.google.com/?q=${indEnquiry.address}`} target="_blank"><p>{indEnquiry.address}</p></a>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Enquiry contact</label>
											<div className={styles.site_contact_flex}>
												<i className="fa-solid fa-user"></i>
												<p>{(indEnquiry.contact.first_name && indEnquiry.contact.last_name && indEnquiry.contact.first_name != '' && indEnquiry.contact.last_name != '') ? `${indEnquiry.contact.first_name} ${indEnquiry.contact.last_name}` : '-'}</p>
											</div>
											<div className={styles.site_contact_flex}>
												<i className="fa-solid fa-envelope"></i>
												{(indEnquiry.contact.email && indEnquiry.contact.email != '')
													? <a className={styles.clickable_nav} href={`mailto:${indEnquiry.contact.email}`}><p>{indEnquiry.contact.email}</p></a>
													: '-'
												}
											</div>
											<div className={styles.site_contact_flex}>
												<i className="fa-solid fa-phone"></i>
												{(indEnquiry.contact.phone && indEnquiry.contact.phone != '')
													? <a className={styles.clickable_nav} href={`tel:${indEnquiry.contact.phone}`}><p>{indEnquiry.contact.phone}</p></a>
													: '-'
												}
											</div>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Reason we could not supply</label>
											<p>{indEnquiry.reason}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Notes</label>
											<p>{indEnquiry.notes || '-'}</p>
										</div>
									</div>
								</div>
							}

						</div>
					</div>
			}
		</div>
	)
}

export default PopUpIndEnquiry;