import Select from 'react-select';
import styles from "./Dashboard.module.css"

import officefruit from '../../../images/officefruit-logo.png'
import officemilk from '../../../images/officemilk-logo.png'
import officebread from '../../../images/officebread-logo.png'
import officecoffee from '../../../images/officecoffee-logo.png'
import officecakes from '../../../images/officecakes-logo.png'
import officecatering from '../../../images/officecatering-logo.png'

import recurring_img from '../../../images/recurring.jpg'
import recurring_change_img from '../../../images/recurring_change.jpg'
import temp_img from '../../../images/temp.jpg'
import onetime_img from '../../../images/onetime.jpg'

import eden_blend from '../../../images/eden_blend.jpg'
import maya_blend from '../../../images/maya_blend.jpg'
import talya_blend from '../../../images/talya_blend.jpg'
import aaron_blend from '../../../images/aaron_blend.jpg'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const PopUpOrder = ({ param, orderTypeNav, formatOptions, popUpPage, popUpControls, newOrderData, handleNewOrderData, orderCategory, handleMainOrder, mainOrder, handleMainObj, changeOrderCategory, inventoryData, mainObj, validationError, deliveryDayOptions, filterDates, accountSites, menuData, flexiOptions, isAlternatingPage, isSubmitPage, currentBoxLoop, flexiMainOrder, flexiMainObj, boxLoops, handleBoxLoops, seasonalExtraItems, isSuperAdmin, bananaWarning, adjustToPreviousDayNoonAEST, overdueWarning, indLoading }) => {
	return (
		<div className={`${styles.popup_container}`}>
			{indLoading
				?
					<div className={styles.loadpage_container_white}>
						<div className={styles.load}>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
				:
					<>
						<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

						{popUpPage === 1 &&
							<>
								<h1 className={styles.medium_font}>Order details</h1>
								<p className={styles.smaller_font}>Please select the type of order you would like to create.</p>

								<div className={`${styles.popup_form}`}>

									<button className={`${styles.order_typebox} ${newOrderData.order_condition && newOrderData.order_condition === "onetime" ? styles.order_typeboxactive : ""}`} onClick={() => handleNewOrderData('order_condition', {value: 'onetime'})}>
										<div className={styles.order_imgbox}>
											<img src={onetime_img}/>
										</div>

										<div className={styles.order_contentbox}>
											<h1 className={styles.smaller_font}>Create a one-time order</h1>
											<p className={styles.smaller_font}>Begin creating a temporary one-time order.</p>
										</div>
									</button>

									<button className={`${styles.order_typebox} ${newOrderData.order_condition && newOrderData.order_condition === "recurring_temp" ? styles.order_typeboxactive : ""}`} onClick={() => handleNewOrderData('order_condition', {value: 'recurring_temp'})}>
										<div className={styles.order_imgbox}>
											<img src={temp_img}/>
										</div>

										<div className={styles.order_contentbox}>
											<h1 className={styles.smaller_font}>Change an ongoing order temporarily</h1>
											<p className={styles.smaller_font}>Make temporary changes to an existing ongoing order.</p>
										</div>
									</button>
								</div>
							</>
						}

						{popUpPage == 2 &&
							<>
								<h1 className={styles.medium_font}>Order details</h1>
								<p className={styles.smaller_font}>Please select the order category you would like to create.</p>

								<div className={`${styles.popup_form_margin}`}>

									<div className={`${styles.popup_form_multi}`}>
										<button className={`${styles.order_catbox} ${newOrderData.order_type && newOrderData.order_type == "Fruit" ? styles.order_catboxactive : ""}`} onClick={() => handleNewOrderData('order_type', {value: 'Fruit'})}>
											<div className={styles.order_catimgbox}>
												<img src={officefruit}/>
											</div>
										</button>

										<button className={`${styles.order_catbox} ${newOrderData.order_type && newOrderData.order_type == "Milk" ? styles.order_catboxactive : ""}`} onClick={() => handleNewOrderData('order_type', {value: 'Milk'})}>
											<div className={styles.order_catimgbox}>
												<img src={officemilk}/>
											</div>
										</button>

										<button className={`${styles.order_catbox} ${newOrderData.order_type && newOrderData.order_type == "Bread" ? styles.order_catboxactive : ""}`} onClick={() => handleNewOrderData('order_type', {value: 'Bread'})}>
											<div className={styles.order_catimgbox}>
												<img src={officebread}/>
											</div>
										</button>

										<button className={`${styles.order_catbox} ${newOrderData.order_type && newOrderData.order_type == "Coffee" ? styles.order_catboxactive : ""}`} onClick={() => handleNewOrderData('order_type', {value: 'Coffee'})}>
											<div className={styles.order_catimgbox}>
												<img src={officecoffee}/>
											</div>
										</button>

										<button className={`${styles.order_catbox} ${newOrderData.order_type && newOrderData.order_type == "Cakes" ? styles.order_catboxactive : ""}`} onClick={() => handleNewOrderData('order_type', {value: 'Cakes'})}>
											<div className={styles.order_catimgbox}>
												<img src={officecakes}/>
											</div>
										</button>

										<button className={`${styles.order_catbox} ${newOrderData.order_type && newOrderData.order_type == "Catering" ? styles.order_catboxactive : ""}`} onClick={() => handleNewOrderData('order_type', {value: 'Catering'})}>
											<div className={styles.order_catimgbox}>
												<img src={officecatering}/>
											</div>
										</button>

									</div>

								</div>

							</>
						}


						{popUpPage == 3 &&
							<>
								<h1 className={styles.medium_font}>{newOrderData.order_type} order details</h1>
								<p className={styles.smaller_font}>Please enter the order details to put in a request.</p>

								{(newOrderData.hasOwnProperty('warning') && newOrderData.warning === true) &&
									<div className={`${styles.notification} ${styles.warningMessage}`}>
										<p className={styles.smaller_font}>You are about to change your ongoing order <span>permanently.</span> To view more options available, go back.</p>
									</div>
								}

								{newOrderData['change_price'] && newOrderData['change_price'] === true &&
									<div className={`${styles.notification} ${styles.warningMessage}`}>
										<p className={styles.smaller_font}>You are about to override the price to <span>${parseFloat(newOrderData['price'])}</span></p>
									</div>
								}

								<div className={`${styles.popup_form}`}>
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Account</label>
										<Select
											closeMenuOnSelect={true}
											isDisabled={newOrderData.edit || newOrderData.navigated}
											options={formatOptions(newOrderData.popup.accounts.map(acc => acc.company))}
											styles={{
												control: (baseStyles, state) => ({
													...baseStyles,
													boxShadow: 'none !important',
												   '&:hover': {
												       borderColor: '#fce7eb !important'
												    },
													borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
												}),
											}}
											onChange={(selected) => handleNewOrderData("account", selected)}
											value={{label: newOrderData["account"], value: newOrderData["account"]}}
										/>
									</div>

									{((accountSites && accountSites.length > 0) || (newOrderData.edit || newOrderData.navigated)) &&
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Delivery site</label>
											<Select
												isDisabled={newOrderData.edit || newOrderData.navigated}
												closeMenuOnSelect={true}
												options={(newOrderData.edit || newOrderData.navigated) ? null : formatOptions(accountSites.map(site => site.site_name))}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewOrderData("delivery_site", selected)}
												value={{label: newOrderData["delivery_site"], value: newOrderData["delivery_site"]}}
											/>
										</div>
									}
									

									{(newOrderData.delivery_site && (deliveryDayOptions && deliveryDayOptions.length > 0)) &&
										<>
											{newOrderData.order_condition && ((newOrderData.order_condition === "recurring_change" && !newOrderData['edit']) || newOrderData.order_condition === "recurring_temp") &&
												<>
												<div className={styles.popup_formbox}>
													<label className={styles.smaller_font}>Delivery day</label>
													<Select
														closeMenuOnSelect={true}
														options={deliveryDayOptions.length > 0 ? formatOptions(deliveryDayOptions) : null}
														styles={{
															control: (baseStyles, state) => ({
																...baseStyles,
																boxShadow: 'none !important',
															   '&:hover': {
															       borderColor: '#fce7eb !important'
															    },
																borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
															}),
														}}
														onChange={(selected) => handleNewOrderData("delivery_day", selected)}
														value={{label: newOrderData["delivery_day"], value: newOrderData["delivery_day"]}}
													>
													</Select>
												</div>
												</>
											}

											{/* changing order temporarily (user selects which date to change in calendar) */}
											{(newOrderData.order_condition && newOrderData.order_condition == 'recurring_temp' && newOrderData.delivery_day) &&
												<div className={styles.popup_formbox}>
													<label className={styles.smaller_font}>Delivery date range</label>
													<LocalizationProvider dateAdapter={AdapterDayjs}>
													    <DateRangePicker
													        className={styles.overridebg}
													        localeText={{ start: '', end: '' }}
													        value={newOrderData.delivery_date_range && newOrderData.delivery_date_range}
													        onChange={(selected) => handleNewOrderData("delivery_date_range", {value: selected})}
													        disablePast
													        format="DD/MM/YYYY"
													    />
													</LocalizationProvider>
												</div>
											}
										</>
									}

									{/* onetime order (user selects which date in calendar for order) */}
									{(newOrderData.order_condition == "onetime" || (newOrderData.order_condition == "recurring_temp" && (newOrderData.edit || newOrderData.navigated))) &&
										<>
											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Delivery date</label>
												<LocalizationProvider dateAdapter={AdapterDayjs}>
													<DatePicker
														disabled={newOrderData.edit || newOrderData.navigated}
														className={styles.overridebg}
														value={newOrderData && newOrderData.delivery_date ? dayjs(newOrderData.delivery_date) : null}
														onChange={(selected) => handleNewOrderData("delivery_date", {value: selected})}
														disablePast
														shouldDisableDate={(date) => new Date(date).getDay() === 0 || new Date(date).getDay() === 6}
														format="DD/MM/YYYY"
													/>
												</LocalizationProvider>
											</div>

											{isSuperAdmin && newOrderData['edit'] &&
												<div className={`${styles.popup_formbox}`}>
													<label className={styles.smaller_font}>Price</label>
													<input type="text" value={newOrderData['price']} onChange={(selected) => handleNewOrderData("price", selected)}/>
												</div>
											}
										</>
									}

									{newOrderData.order_type == "Fruit" &&
										<>
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Box type</label>
											<Select
												isDisabled={newOrderData.edit || newOrderData.navigated}
												closeMenuOnSelect={true}
												options={formatOptions(["Seasonal", "Flexi"])}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleNewOrderData("box_type", selected)}
												value={{label: newOrderData["box_type"], value: newOrderData["box_type"]}}
											>
											</Select>
										</div>

										{newOrderData.hasOwnProperty('auto_assign_boxes') &&
											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Auto box assignment?</label>
												<div className={styles.radio_container}>
													<label className={styles.radio_button}>Yes
												  		<input
												  			type="radio"
												  			name="radio"
												  			checked={newOrderData.auto_assign_boxes === true && "checked"}
												  			onChange={(selected) => handleNewOrderData("auto_assign_boxes", {value: true})}
												  		/>
												    	<span className={styles.checkmark}></span>
												  	</label>

												  	<label className={styles.radio_button}>No
												    	<input
												    		type="radio"
												    		name="radio"
												  			checked={newOrderData.auto_assign_boxes === false && "checked"}
												  			onChange={(selected) => handleNewOrderData("auto_assign_boxes", {value: false})}
												    	/>
												    	<span className={styles.checkmark}></span>
												  	</label>
												</div>
											</div>
										}

										{!(newOrderData.hasOwnProperty('auto_assign_boxes') && newOrderData.auto_assign_boxes) &&
											<div className={`${styles.popup_formbox}`}>
												<label className={styles.smaller_font}>No. Boxes</label>
												<input type="text" value={boxLoops} onChange={(input) => handleBoxLoops(input)}/>
											</div>
										}
										</>
									}
								</div>
							</>
						}


						{(newOrderData.hasOwnProperty('auto_assign_boxes') && newOrderData.auto_assign_boxes)
						?
							<>
								{popUpPage == 4 &&
									<>
										<h1 className={styles.medium_font}>Order details</h1>
										<p className={styles.smaller_font}>Please select the specific items you would like.</p>

										<div className={`${styles.notification} ${styles.warningMessage}`}>
											<p className={styles.smaller_font}>Items under <span>extra items</span> come at an extra cost.</p>
										</div>

										{overdueWarning &&
											<div className={`${styles.notification} ${styles.warningMessage}`}>
												<p className={styles.smaller_font}>Some items you are selected/are in this order are past their cut-off date. You will not be able to add/edit any of those items.</p>
											</div>
										}

										<button className={`${styles.sitenav_box} ${orderCategory == "Fruit" && styles.sitenav_active}`} onClick={() => changeOrderCategory("Fruit", orderTypeNav)}><p>Fruit</p></button>
										<button className={`${styles.sitenav_box} ${orderCategory == "Extra Items" && styles.sitenav_active}`} onClick={() => changeOrderCategory("Extra Items", orderTypeNav)}><p>Extra Items</p></button>

										<div className={`${styles.popup_form} ${styles.popup_formorder}`}>
											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>{`${newOrderData.order_type == "Fruit" ? "Fruit" : orderCategory} selection`}</label>

												{Object.keys(flexiOptions).map((FO) => {
													return (
														<>
															{orderCategory == FO &&
																<>
																	<Select
																		closeMenuOnSelect={true}
																		isMulti
																		options={formatOptions(flexiOptions[FO])}
																		styles={{
																			control: (baseStyles, state) => ({
																				...baseStyles,
																				boxShadow: 'none !important',
																			   '&:hover': {
																			       borderColor: '#fce7eb !important'
																			    },
																				borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
																			}),
																		}}
																		value={flexiMainOrder[currentBoxLoop] && flexiMainOrder[currentBoxLoop][orderCategory] && formatOptions(flexiMainOrder[currentBoxLoop][orderCategory])}
																		onChange={(selected) => handleMainOrder(selected)}
																	/>
																</>
															}
														</>
													)
												})}
											</div>
										</div>
									</>
								}
							</>
						:
							<>
					            {isAlternatingPage(popUpPage) && 
					                <>
					                	{(popUpPage - 4) % 2 === 0
					                		?
					                			<>
													<h1 className={styles.medium_font}>{boxLoops !== 0 && `Box ${currentBoxLoop + 1} - `}{(newOrderData['box_type'] && newOrderData['box_type'] == "Seasonal") ? "Exclusion" : "Order"} details</h1>
													<p className={styles.smaller_font}>Please select the specific items you would like {(newOrderData['box_type'] && newOrderData['box_type'] == "Seasonal") ? "to exclude." : "."}</p>

													{(newOrderData['box_type'] && newOrderData['box_type'] == "Seasonal") &&
														<div className={`${styles.notification} ${styles.warningMessage}`}>
															<p className={styles.smaller_font}>Please only select items that should be <span>excluded</span> from this seasonal order, unless you are adding Extra Items.</p>
														</div>
													}

													{((newOrderData['box_type'] && (newOrderData['box_type'] == "Flexi" || newOrderData['box_type'] == "Seasonal")) && orderCategory === "Extra Items") &&
														<div className={`${styles.notification} ${styles.warningMessage}`}>
															<p className={styles.smaller_font}>Items under <span>extra items</span> come at an extra cost.</p>
														</div>
													}

													{overdueWarning &&
														<div className={`${styles.notification} ${styles.warningMessage}`}>
															<p className={styles.smaller_font}>Some items you are selected/are in this order are past their cut-off date. You will not be able to add/edit any of those items.</p>
														</div>
													}

													<div className={`${styles.innersitenav} ${(newOrderData.order_type == "Cakes" || newOrderData.order_type == "Catering") && styles.overflowX}`}>
													{newOrderData.order_type == "Fruit"
													    ?
													    	<>
												    			<button className={`${styles.sitenav_box} ${orderCategory == "Fruit" && styles.sitenav_active}`} onClick={() => changeOrderCategory("Fruit", orderTypeNav)}><p>Fruit</p></button>
												    			<button className={`${styles.sitenav_box} ${orderCategory == "Extra Items" && styles.sitenav_active}`} onClick={() => changeOrderCategory("Extra Items", orderTypeNav)}><p>Extra Items</p></button>
													    	</>
													    : newOrderData.order_type == "Coffee"
													    ? <button className={`${styles.sitenav_box} ${styles.sitenav_active}`}><p>Coffee</p></button>
													    :
													    <>
													        {inventoryData &&
													            <>
													                {[...new Set(inventoryData.filter(inv => inv.category == orderTypeNav).map(invitem => invitem.subcategory))].map((IM, imIndex) => {
													                    return (
													                        <button className={`${styles.sitenav_box} ${(newOrderData.order_type == "Cakes" || newOrderData.order_type == "Catering") && styles.flexShrink} ${orderCategory == IM && styles.sitenav_active}`} onClick={() => changeOrderCategory(IM, orderTypeNav)}><p>{IM}</p></button>
													                    )
													                })}
													            </>
													        }
													    </>
													}

													</div>

													<div className={`${styles.popup_form} ${styles.popup_formorder}`}>
														<div className={styles.popup_formbox}>
															<>
																{newOrderData.order_type !== "Coffee" &&
																	<label className={styles.smaller_font}>{`${newOrderData.order_type == "Fruit" ? "Fruit" : orderCategory} ${(newOrderData['box_type'] && newOrderData['box_type'] == "Seasonal") ? "exlusions" : "selection"}`}</label>
																}

																{(newOrderData.order_type === "Fruit")
																?
																	<>
																		{newOrderData.box_type === "Seasonal" 
																		?
																			<>
																				{orderCategory === "Extra Items"
																					?
																						<>
																						<Select
																							closeMenuOnSelect={true}
																							isMulti
																							options={
																								formatOptions(seasonalExtraItems)
																							}
																							styles={{
																								control: (baseStyles, state) => ({
																									...baseStyles,
																									boxShadow: 'none !important',
																								   '&:hover': {
																								       borderColor: '#fce7eb !important'
																								    },
																									borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
																								}),
																							}}
																							value={mainOrder[orderCategory] && mainOrder[orderCategory][currentBoxLoop] && formatOptions(mainOrder[orderCategory][currentBoxLoop])}
																							onChange={(selected) => handleMainOrder(selected)}
																						/>
																						</>
																					:
																						<Select
																							closeMenuOnSelect={true}
																							isMulti
																							options={
																								formatOptions(newOrderData.order_type == "Fruit" ? menuData[0].items.map(item => inventoryData.find(inventoryItem => inventoryItem._id === item.product).item_name) : inventoryData.filter(inv => inv.category == orderTypeNav).filter(invitem => invitem.subcategory == orderCategory).map(invind => invind.item_name))
																							}
																							styles={{
																								control: (baseStyles, state) => ({
																									...baseStyles,
																									boxShadow: 'none !important',
																								   '&:hover': {
																								       borderColor: '#fce7eb !important'
																								    },
																									borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
																								}),
																							}}
																							value={mainOrder[orderCategory] && mainOrder[orderCategory][currentBoxLoop] && formatOptions(mainOrder[orderCategory][currentBoxLoop])}
																							onChange={(selected) => handleMainOrder(selected, true)}
																						/>
																				}
																			</>
																		:
																			// flexiOptions
																			<>
																				{Object.keys(flexiOptions).map((FO) => {
																					return (
																						<>
																							{orderCategory == FO &&
																								<>
																									<Select
																										closeMenuOnSelect={true}
																										isMulti
																										options={
																											formatOptions(flexiOptions[FO])
																										}
																										styles={{
																											control: (baseStyles, state) => ({
																												...baseStyles,
																												boxShadow: 'none !important',
																											   '&:hover': {
																											       borderColor: '#fce7eb !important'
																											    },
																												borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
																											}),
																										}}
																										// value={mainOrder[orderCategory] && formatOptions(mainOrder[orderCategory])}
																										value={flexiMainOrder[currentBoxLoop] && flexiMainOrder[currentBoxLoop][orderCategory] && formatOptions(flexiMainOrder[currentBoxLoop][orderCategory])}
																										onChange={(selected) => handleMainOrder(selected)}
																									/>
																								</>
																							}
																						</>
																					)

																				})}
																			</>
																		}
																	</>
																: newOrderData.order_type == "Coffee"
																?
																	<div className={`${styles.popup_form_coffee}`}>

																		<button className={`${styles.order_catbox} ${(mainOrder[orderCategory] && mainOrder[orderCategory].includes('Eden Blend')) && styles.order_catboxactive}`} onClick={() => handleMainOrder([{value: 'Eden Blend'}])}>
																			<div className={styles.order_coffeebox}>
																				<img src={eden_blend}/>
																			</div>
																		</button>

																		<button className={`${styles.order_catbox} ${(mainOrder[orderCategory] && mainOrder[orderCategory].includes('Maya Blend')) && styles.order_catboxactive}`} onClick={() => handleMainOrder([{value: 'Maya Blend'}])}>
																			<div className={styles.order_coffeebox}>
																				<img src={maya_blend}/>
																			</div>
																		</button>

																		<button className={`${styles.order_catbox} ${(mainOrder[orderCategory] && mainOrder[orderCategory].includes('Talya Blend')) && styles.order_catboxactive}`} onClick={() => handleMainOrder([{value: 'Talya Blend'}])}>
																			<div className={styles.order_coffeebox}>
																				<img src={talya_blend}/>
																			</div>
																		</button>

																		<button className={`${styles.order_catbox} ${(mainOrder[orderCategory] && mainOrder[orderCategory].includes('Aaron Blend')) && styles.order_catboxactive}`} onClick={() => handleMainOrder([{value: 'Aaron Blend'}])}>
																			<div className={styles.order_coffeebox}>
																				<img src={aaron_blend}/>
																			</div>
																		</button>
																	</div>
																:
																	<>
																	{inventoryData &&
																		<>
																			{[...new Set(inventoryData.filter(inv => inv.category == orderTypeNav).map(invitem => invitem.subcategory))].map((MS) => {
																				
																				return (
																					<>
																						{orderCategory == MS &&
																							<>
																								<Select
																									// isDisabled={newOrderData['edit']}
																									closeMenuOnSelect={true}
																									isMulti
																									options={
																										formatOptions(newOrderData.order_type == "Fruit" ? menuData[0].items.map(item => inventoryData.find(inventoryItem => inventoryItem._id === item.product).item_name) : inventoryData.filter(inv => inv.category == orderTypeNav).filter(invitem => invitem.subcategory == orderCategory).map(invind => invind.item_name))
																									}
																									styles={{
																										control: (baseStyles, state) => ({
																											...baseStyles,
																											boxShadow: 'none !important',
																										   '&:hover': {
																										       borderColor: '#fce7eb !important'
																										    },
																											borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
																										}),
																									}}
																									value={mainOrder[orderCategory] && formatOptions(mainOrder[orderCategory])}
																									onChange={(selected) => handleMainOrder(selected)}
																								/>
																							</>
																						}
																					</>
																				)
																			})}
																		</>
																	}
																	</>
																}
															</>
														</div>
													</div>
					                			</>
					                		:
												<>
													<h1 className={styles.medium_font}>{boxLoops !== 0 && `Box ${currentBoxLoop + 1} - `}Quantity details</h1>
													<p className={styles.smaller_font}>Please enter the quantities for the items you selected.</p>

													{overdueWarning &&
														<div className={`${styles.notification} ${styles.warningMessage}`}>
															<p className={styles.smaller_font}>Some items you are selected/are in this order are past their cut-off date. You will not be able to add/edit any of those items.</p>
														</div>
													}

													<div className={`${styles.popup_form} ${styles.popup_formorder}`}>
														<div className={styles.popup_formbox}>

															{(newOrderData['box_type'] && newOrderData['box_type'] == "Seasonal")
															?
																<div className={`${styles.popup_form_nm}`}>
																	<div className={`${styles.popup_formbox}`}>
																		<label className={styles.smaller_font}>Total Box - Quantity</label>
																		<input type="text" name="total_qty" placeholder="Qty in pieces" value={newOrderData[`box_qty${currentBoxLoop}`] && newOrderData[`box_qty${currentBoxLoop}`]} onChange={(selected) => handleNewOrderData(`box_qty${currentBoxLoop}`, selected)}/>
																	</div>

																	{(mainOrder.hasOwnProperty('Extra Items') && mainOrder['Extra Items'].length > currentBoxLoop) &&
																		<>
																			{/* {boxLoops !== 1 && <div className={styles.box_line_break}></div>} */}

																			{Object.values(mainOrder['Extra Items'][currentBoxLoop]).flatMap(valueArray => valueArray).map((EO) => {
																				let itemFoundFromKey = inventoryData.filter(item => item.item_name === EO)[0]

																				return (
																					<>
																						<div className={`${styles.popup_formbox}`}>
																							<label className={styles.smaller_font}>{EO} - Quantity</label>
																							<input
																								disabled={itemFoundFromKey['custom_cutoff'] ? (adjustToPreviousDayNoonAEST(newOrderData.delivery_date, itemFoundFromKey.custom_cutoff)) : false}
																								type="text"
																								name={EO}
																								placeholder={`Qty in ${newOrderData.order_type === "Fruit" ? "pieces" : newOrderData.order_type === "Milk" ? "bottles" : newOrderData.order_type === "Coffee" ? "bags" : "items"}`}
																								onChange={(selected) => handleMainObj(selected)}
																								value={mainObj['Extra Items'][currentBoxLoop][EO] && mainObj['Extra Items'][currentBoxLoop][EO]}
																							/>
																						</div>
																					</>
																				)
																			})}
																		</>
																	}
																</div>
															:
																<div className={`${styles.popup_form_nm} ${styles.popup_scrollable}`}>
																	{(newOrderData['box_type'] && newOrderData['box_type'] == "Flexi")
																		?
																		<>
																			{Object.values(flexiMainOrder[currentBoxLoop]).flatMap(valueArray => valueArray).map((MO) => {
																				let itemFoundFromKey = inventoryData.filter(item => item.item_name === MO)[0]

																				return (
																					<>
																						<div className={`${styles.popup_formbox}`}>
																							<label className={styles.smaller_font}>{MO} - Quantity</label>
																							<input
																								disabled={itemFoundFromKey['custom_cutoff'] && adjustToPreviousDayNoonAEST(newOrderData.delivery_date, itemFoundFromKey.custom_cutoff)}
																								type="text"
																								name={MO}
																								placeholder={`Qty in ${newOrderData.order_type === "Fruit" ? "pieces" : newOrderData.order_type === "Milk" ? "bottles" : newOrderData.order_type === "Coffee" ? "bags" : "items"}`}
																								onChange={(selected) => handleMainObj(selected)}
																								// value={flexiMainObj[Object.keys(flexiMainOrder[currentBoxLoop]).find(key => flexiMainOrder[currentBoxLoop][key].includes(MO))][currentBoxLoop][MO]}
																								value={Object.values(flexiMainObj[currentBoxLoop]).find(item => item[MO])?.[MO]}

																							/>
																						</div>
																					</>
																				)
																			})}

																			<div className={styles.popup_formbox}>
																				<label className={styles.smaller_font}>Total quantity</label>
																				<input type="text" value={Object.values(flexiMainObj[currentBoxLoop]).flatMap(Object.values).reduce((acc, val) => (acc === '' ? 0 : Number(acc)) + (val === '' ? 0 : Number(val)), 0) || 0} disabled={true}/>
																			</div>
																		</>
																		:
																		<>
																			{Object.values(mainOrder).flatMap(valueArray => valueArray).map((MO) => {
																				let itemFoundFromKey = inventoryData.filter(item => item.item_name === MO)[0]

																				return (
																					<>
																						<div className={`${styles.popup_formbox}`}>
																							<label className={styles.smaller_font}>{MO} - Quantity</label>
																							<input
																								disabled={(itemFoundFromKey['custom_cutoff'] && newOrderData['delivery_date']) && adjustToPreviousDayNoonAEST(newOrderData.delivery_date, itemFoundFromKey.custom_cutoff)}
																								type="text"
																								name={MO}
																								placeholder={`Qty in ${newOrderData.order_type === "Fruit" ? "pieces" : newOrderData.order_type === "Milk" ? "bottles" : newOrderData.order_type === "Coffee" ? "bags" : "items"}`}
																								onChange={(selected) => handleMainObj(selected)}
																								value={mainObj[Object.keys(mainOrder).find(key => mainOrder[key].includes(MO))][MO]}
																							/>
																						</div>
																					</>
																				)
																			})}

																			<div className={styles.popup_formbox}>
																				<label className={styles.smaller_font}>Total quantity</label>
																				<input type="text" value={Object.values(mainObj).flatMap(Object.values).reduce((acc, val) => (acc === '' ? 0 : Number(acc)) + (val === '' ? 0 : Number(val)), 0) || 0} disabled={true}/>
																			</div>
																		</>
																	}
																</div>
															}
														</div>
													</div>
					                			</>
					                	}
					                </>
					            }
				            </>
			        	}


			        	{(newOrderData.hasOwnProperty('auto_assign_boxes') && newOrderData.auto_assign_boxes)
			        	?
			        		<>
			        			{popUpPage == 5 &&
			        				<>
										<h1 className={styles.medium_font}>Quantity details</h1>
										<p className={styles.smaller_font}>Please enter the quantities for the items you selected.</p>
											
										{overdueWarning &&
											<div className={`${styles.notification} ${styles.warningMessage}`}>
												<p className={styles.smaller_font}>Some items you are selected/are in this order are past their cut-off date. You will not be able to add/edit any of those items.</p>
											</div>
										}

										<div className={`${styles.popup_form} ${styles.popup_formorder}`}>
											<div className={styles.popup_formbox}>

												<div className={`${styles.popup_form_nm} ${styles.popup_scrollable}`}>
													{Object.values(flexiMainOrder[currentBoxLoop]).flatMap(valueArray => valueArray).map((MO) => {
														let itemFoundFromKey = inventoryData.filter(item => item.item_name === MO)[0]

														return (
															<>
																<div className={`${styles.popup_formbox}`}>
																	<label className={styles.smaller_font}>{MO} - Quantity</label>
																	<input
																		disabled={itemFoundFromKey['custom_cutoff'] && adjustToPreviousDayNoonAEST(newOrderData.delivery_date, itemFoundFromKey.custom_cutoff)}
																		type="text"
																		name={MO}
																		placeholder={`Qty in ${newOrderData.order_type === "Fruit" ? "pieces" : newOrderData.order_type === "Milk" ? "bottles" : newOrderData.order_type === "Coffee" ? "bags" : "items"}`}
																		onChange={(selected) => handleMainObj(selected)}
																		value={Object.values(flexiMainObj[currentBoxLoop]).find(item => item[MO])?.[MO]}

																	/>
																</div>
															</>
														)
													})}

													<div className={styles.popup_formbox}>
														<label className={styles.smaller_font}>Total quantity</label>
														<input type="text" value={Object.values(flexiMainObj[currentBoxLoop]).flatMap(Object.values).reduce((acc, val) => (acc === '' ? 0 : Number(acc)) + (val === '' ? 0 : Number(val)), 0) || 0} disabled={true}/>
													</div>
												</div>
											</div>
										</div>
			        				</>
			        			}


			        			{popUpPage == 6 &&
			        				<>
										<h1 className={styles.medium_font}>Confirm order</h1>
										<p className={styles.smaller_font}>Please confirm all the details you have entered.</p>

										{bananaWarning &&
											<div className={`${styles.notification} ${styles.warningMessage}`}>
												<p className={styles.smaller_font}>55 bananas will not fit in 1 box. You can continue if you would like.</p>
											</div>
										}

										<div className={`${styles.popup_form} ${styles.popup_scrollable}`}>
											{Object.values(flexiMainOrder[0]).flatMap(valueArray => valueArray).map((MT) => {
												return (
													<>
														<div className={styles.popup_formbox}>
															<label className={styles.smaller_font}>{MT}</label>
															<input type="text" value={Object.values(flexiMainObj[0]).find(item => item[MT])?.[MT]} disabled={true}/>
														</div>
													</>
												)
											})}

											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Total quantity</label>
												<input type="text" value={Object.values(flexiMainObj[0]).flatMap(Object.values).reduce((sum, value) => sum + parseInt(value, 10), 0) || 0} disabled={true}/>
											</div>
										</div>
									</>
								}
			        		</>
			        	:
			        		<>
								{isSubmitPage(popUpPage) &&
									<>
										<h1 className={styles.medium_font}>Confirm order</h1>
										<p className={styles.smaller_font}>Please confirm all the details you have entered.</p>

										{bananaWarning &&
											<div className={`${styles.notification} ${styles.warningMessage}`}>
												<p className={styles.smaller_font}>55 bananas will not fit in 1 box. You can continue if you would like.</p>
											</div>
										}

										<div className={`${styles.popup_form} ${styles.popup_scrollable}`}>
											{newOrderData['box_type'] == "Flexi"
											?
												<>
													{Array.from({ length: boxLoops }).map((_, index) => {
														return (
															<>
																{Object.values(flexiMainOrder[index]).flatMap(valueArray => valueArray).map((MT) => {
																	return (
																		<>
																			<div className={styles.popup_formbox}>
																				<label className={styles.smaller_font}>{MT}</label>
																				<input type="text" value={Object.values(flexiMainObj[index]).find(item => item[MT])?.[MT]} disabled={true}/>
																			</div>
																		</>
																	)
																})}

																<div className={styles.popup_formbox}>
																	<label className={styles.smaller_font}>Box {index+1} - Total quantity</label>
																	<input type="text" value={Object.values(flexiMainObj[index]).flatMap(Object.values).reduce((sum, value) => sum + parseInt(value, 10), 0) || 0} disabled={true}/>
																</div>

																{index !== boxLoops-1 && <div className={styles.box_line_break}></div>}
															</>
														)
													})}
												</>
											:
												<>
													{newOrderData['box_type'] !== "Seasonal" &&
														<>
															{[...new Set(inventoryData.filter(inv => inv.category == orderTypeNav).map(invitem => invitem.subcategory))].map((MT) => {
																return (
																	<>
																		{mainObj && mainObj[MT] && Object.keys(mainObj[MT]).map((MI) => {
																			return (
																				<div className={styles.popup_formbox}>
																					<label className={styles.smaller_font}>{MI}</label>
																					<input type="text" value={mainObj[MT][MI]} disabled={true}/>
																				</div>
																			)
																		})}
																	</>
																)
															})}
														</>
													}
												</>
											}

											{newOrderData['box_type'] === "Seasonal"
											?
												<>
													{Array.from({ length: boxLoops }).map((_, index) => {
														return (
															<>
															<div className={styles.popup_formbox}>
																<label className={styles.smaller_font}>Box {index+1} - Total quantity</label>
																<input type="text" value={newOrderData[`box_qty${index}`]} disabled={true}/>
															</div>	

															{(mainOrder.hasOwnProperty('Extra Items') && mainOrder['Extra Items'].length > index) &&
																<>
																	{Object.values(mainOrder['Extra Items'][index]).flatMap(valueArray => valueArray).map((EO) => {
																		return (
																			<>
																				<div className={`${styles.popup_formbox}`}>
																					<label className={styles.smaller_font}>{EO} - Quantity</label>
																					<input
																						type="text"
																						disabled={true}
																						value={mainObj['Extra Items'][index][EO] && mainObj['Extra Items'][index][EO]}
																					/>
																				</div>
																			</>
																		)
																	})}

																	{index !== boxLoops-1 && <div className={styles.box_line_break}></div>}
																</>
															}
															</>
														)
													})}
												</>
											:
												<>
													{newOrderData['box_type'] !== "Flexi" &&
														<div className={styles.popup_formbox}>
															<label className={styles.smaller_font}>Total quantity</label>
															<input type="text" value={Object.values(mainObj).flatMap(Object.values).reduce((acc, val) => (acc === '' ? 0 : Number(acc)) + (val === '' ? 0 : Number(val)), 0) || 0} disabled={true}/>
														</div>
													}
												</>
											}
										</div>
									</>
								}
							</>
						}

						{validationError &&
							<div className={`${styles.notification} ${styles.validationError}`}>
								<p className={styles.smaller_font}>{validationError}</p>
							</div>
						}

						<div className={styles.popUpControls}>
			                {(newOrderData['order_type'] === "Fruit" && currentBoxLoop !== 0 && popUpPage > 3 && popUpPage % 2 === 0 && !isSubmitPage(popUpPage)) &&
			                	<button type="button" className={styles.cloneBtn} onClick={() => popUpControls('clone_box')}>Clone previous box</button>
			            	}

							<div className={styles.popUpControlsContainer}>
					      		{popUpPage !== 1 &&
					      			<>
					      				{newOrderData['edit']
					      					? <> {popUpPage !== 3 && <button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>} </>
					      					: <button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
					      				}
					      			</>
					      		}

				                {(!isSubmitPage(popUpPage) && popUpPage !== 2) &&
				                    <button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
				                }

				                {isSubmitPage(popUpPage) &&
				                    <button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
				                }
				       		</div>
			       		</div>
       			</>
       		}
		</div>
	)
}

export default PopUpOrder;